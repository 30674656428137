@baseColor: #f5f5f5; 
@textColor: #333; 
@primaryColor: #007bff; 

.about-me {
  background-color: @baseColor;
  padding: 4rem 2rem;
  margin: 2rem 0;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.about-me__heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

.about-me__content {
  line-height: 1.5;
  font-size: 16px;
  text-align: justify;
  margin-left: 10px;
  margin-right: 10px;
}

.about-me__profile-pic {
  display: grid;
  margin: 0 auto 1rem;
  border-radius: 50%;
  width: 14rem;
  height:21em;

}

.about-me__links {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.about-me__links a {
  margin: 0 1rem;
  font-weight: bold;
  color: @textColor;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.about-me__download-resume {
  display: block;
  margin: 1rem auto;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: @primaryColor;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 768px) {
  .about-me {
    padding: 3rem 1rem;
  }

  .about-me__heading {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .about-me__content {
    font-size: 14px;
  }
}
