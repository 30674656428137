/* Base styles for projects */
.project {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem; /* Adjust spacing as needed */
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px; /* Increase padding for content readability */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
  /* Center the project horizontally */
  margin: 0 auto;
  width: fit-content;
}

.project img {
    width: 40%; /* Adjust image width for side-by-side layout */
  }
/* Project Image (Centered) */

/* Project Content */
.project-content {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Add space between content sections */
  padding: 1.5rem; /* Add padding for better visual separation */
  background-color: #fff; /* Set a light background color */
  border-radius: 5px; /* Add subtle rounded corners */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Project Title */
.project h3 {
  margin: 0 0 15px 0; /* Adjust top and bottom margin */
  font-size: 1.2rem; /* Set font size for title */
}

/* Project Description */
.project-description {
  margin-bottom: 1rem; /* Adjust space below description */
  line-height: 1.5; /* Increase line spacing for readability */
}

/* Project Information (.project-info) */
.project-info {
  display: flex;
  flex-wrap: wrap; /* Allow info items to wrap on smaller screens */
  margin-bottom: 1rem; /* Adjust space below info */
  list-style: none; /* Remove default list style for clarity */
  padding: 0; /* Remove default padding */
  font-size: 14px; /* Set font size for info labels and content */
  margin: 0.5rem 0.8rem;
}

.project-info li {
  display: flex;
  align-items: center; /* Align label and content vertically */
  margin: 0.5rem 0.5rem; /* Adjust spacing between list items */
  color: #333; /* Use a darker color for better contrast */
}

.info-label {
  font-weight: bold; /* Make info labels bold */
  margin-right: 5px; /* Add space between label and content */
  flex: 0 0 auto; /* Prevent label from growing horizontally */
}

/* Project Meta Information (.project-meta) */
.project-meta {
  display: flex;
  justify-content: space-between; /* Align items horizontally */
  margin-bottom: 0; /* Remove bottom margin */
  font-size: 13px; /* Set font size for meta info */
  color: #888; /* Use a lighter color for meta info */
}

/* Project Link */
.project-link {
  text-decoration: none; /* Remove underline from link */
  color: #007bff; /* Set link color */
  font-weight: bold; /* Make link text bold */
  margin-top: auto; /* Align link to bottom of content */
  /* Optional: Add hover effect for link (consider using a hover pseudo-class) */
}

/* Media Queries for Responsive Design (Optional) */
@media (min-width: 768px) {
  .project {
    flex-direction: row; /* Arrange image and content side-by-side on larger screens */
    width: calc(50% - 1rem); /* Allow two projects per row with spacing */
  }
  .project img {
    width: 40%; /* Adjust image width for side-by-side layout */
  }
  .project-content {
    flex-grow: unset; /* Disable content growth on larger screens */
  }
}
