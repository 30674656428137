/* Base Styles */
.header {
  height: 8rem; /* Adjust height as needed */
  background-color: #fff; /* Light background */
  color: #333; /* Dark text color */
  display: flex;
  justify-content: right;
  align-items: center; /* Align vertically to the center */
  padding: 0 2rem; /* Remove top/bottom padding */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: all 0.2s ease-in-out; /* Add transition for smooth changes */
  width: 110%;
}

/* Logo or Title Styling */
.header-title {
  flex: 1; /* Allow logo/title to grow horizontally */
  text-align: right; /* Align text to the left */
}

.header-title img.logo {
  width: 100px; /* Adjust logo size as needed */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure image fills container while maintaining aspect ratio */
}

.header-title h1 {
  margin: 0; /* Remove default margin */
  font-size: 1.5rem; /* Adjust font size */
  font-weight: bold;
}

/* Navigation Styling */
.navigation {
  display: flex;
  list-style: none; /* Remove default list style (bullets) */
  padding: 0;
  margin: 0;
   align-items: right; /* Align navigation links vertically */
   list-style: none;
   justify-content: right;
  font-family: sans-serif;
}

.navigation-list {
  display: flex;
  align-items: right; /* Align navigation links vertically */
   list-style: none;
   justify-content: right;
}

.navigation li {
  margin-right: 1rem; /* Add space between navigation links */

}

.navigation a {
  text-decoration: none;
  color: #333;
  font-weight: 500; /* Moderate weight for links */
  transition: color 0.2s ease;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.navigation a:hover {
  color: #007bff; /* Blue hover color */
}

/* Responsive Design */
@media (max-width: 768px) {
  .header {
    padding: 1rem; /* Adjust padding for smaller screens */
  }

  .header-title {
    flex: none; /* Stop logo/title from growing on small screens */
  }

  .navigation {
    flex-direction: column; /* Stack navigation links vertically */
    align-items: stretch; /* Stretch links to fill width */
    text-align: center; /* Center navigation text */
  }

  .navigation li {
    margin: 0.5rem 0; /* Adjust spacing for stacked navigation */
  }
}
