.portfolio-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); /* Responsive grid layout */
  gap: 20px;
  justify-content: center ;
}

.portfolio-item {
  background-color: #f5f5f5; /* Light gray background */
  border-radius: 5px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: all 0.3s ease; /* Smooth transition on hover */
  cursor: pointer; /* Indicate interactivity on hover */
}

.portfolio-item:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); /* Emphasized shadow on hover */
  transform: translateY(-2px); /* Slight elevation on hover */
}



