/* Base styles */
@base-font-family: Arial, sans-serif;
@base-background-color: #f5f5f5;

/* Text colors */
@text-color: #fff;
@primary-color: #333;
@secondary-color: #e0e0e0;
@accent-color: #222;

/* Home container */

.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: @base-background-color;
  font-family: @base-font-family;
  margin: 0;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
}

.home-header,
.home-main,
.home-footer {
  flex: 1;
}

.home-header {
  padding: 20px;
  text-align: center;
}

h1 {
  margin: 0;
  font-size: 2em;
}

.home-main {
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex-wrap: wrap;
  text-align: center;

}

.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: @base-background-color;
  font-family: @base-font-family;
  margin: 0;
  padding: 0 20px;
}

/* Sections within the home container */
.home-hero,
.home-main,
.home-contact,
.home-project
 {
  flex: 1; /* Allow growth but don't force full width */
  display: flex;
  justify-content: center; /* Center each section horizontally */
  align-items: center; /* Center each section vertically */
}

/* Hero section styles */
.home-hero {
  height: 50vh; /* Adjust height as needed */
  width: 100%; /* Ensure full width on all screens */
  padding: 40px;
  background-size: cover;
  background-position: center;
  color: @text-color;
}

.hero-content {
  text-align: center; /* Center hero text */
  color: black;
}

h1 {
  margin: 0;
  font-size: 2.5em;
  font-weight: bold;
}



.hero-btn:hover {
  background-color: @accent-color;
}

/* Hero image styles */
.hero-image {
  width: 50%;
  height: auto;
  object-fit: cover;
}

/* Main content section styles */
.home-main {
  flex-direction: column; /* Stack sections vertically */
  gap: 40px;
}

.about-me,


h2 {
  margin: 0;
  font-size: 1.3em;
}

p {
  font-size: 1em;
  line-height: 1.5;
}

/* Contact section styles */


/* Responsive styles */

/* Large screens (>= 960px) */
@media (min-width: 960px) {
  .home-main {
    flex-direction: row; /* Change to row for horizontal layout */
    justify-content: center; /* Distribute content with some spacing */
  }

  .about-me,
  .projects {
    flex: 1; /* Allow sections to grow and shrink proportionally */
   
  }
}

/* Extra large screens (>= 1440px) */
/* Extra large screens (>= 1440px) */
@media (min-width: 1440px) {

  /* Main content section */
  .home-main {
    gap: 50px; /* Increase spacing between sections for a more spacious feel */
  }

  .about-me,
  .projects {
   /* Adjust section width to create more breathing room */
    padding: 30px; /* Increase padding for a more comfortable reading experience */
  }

  /* Typography adjustments */
  h1 {
    font-size: 3em; /* Increase heading size for a more impactful presentation */
  }

  h2 {
    font-size: 1.5em; /* Increase subheading size for better hierarchy */
  }

  p {
    font-size: 1.1em; /* Increase paragraph size for improved readability */
  }

  /* Consider adding subtle hover effects or animations for a more interactive feel */
  .about-me:hover,
  .projects:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle hover shadow */
  }
}

/* Mobile screens (<= 480px) */


/* Default iPad screens (device-width: 768px) */
@media (device-width: 768px) {
  /* You can add specific styles for the default iPad portrait layout here (optional) */
  .about-me,
  .projects {
    font-size: 1rem; /* Adjust font size for better readability */
  }
}

/* Portrait Layouts (481px - 1024px) */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  .home-main {
    flex-direction: column; /* Stack sections vertically on portrait tablets */
  }

  .about-me,
  .projects,
  .skills {
    flex: 1 100%; /* Ensure full width on portrait tablets */
    margin: 0 auto;  /* Center each section horizontally */
  }
}

/* Landscape Layouts (481px - 1024px) */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
  .home-main {
    flex-direction: row; /* Change to row for horizontal layout on landscape tablets */
    justify-content: center; /* Distribute content with some spacing */
  }

  .about-me,
  .projects {
    flex: 1; /* Allow sections to grow and shrink proportionally */
    max-width: 45%; /* Set a maximum width to prevent excessive stretching */
    font-size: 1rem; /* Adjust font size for better readability */
  }

  .skills {  /* Include Skills component in media query */
    margin: 0 auto;  /* Center the Skills component horizontally */
  }
}
