.portfolio {
  max-width: 1000px;
  max-height: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5; 
}

.portfolio-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.portfolio-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333; 
}

.portfolio-description {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #666; 
  text-align: center;
  max-width: 800px; 
}

.portfolio-filters {
  display: flex;
justify-content: center;
  margin-bottom: 20px;
}

.portfolio-filters button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: all 0.3s ease; 
}

.portfolio-filters button:hover {
  background-color: #eaeaea;
}

.portfolio-filters button.active {
  background-color: #007bff; 
  color: #fff; 
}

.portfolio-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  outline: none; 
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 

}

.portfolio-item:hover {
  transform: translateY(-2px);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.portfolio-item:focus {
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
}

.portfolio-item-image {
  width: 100%;
  max-height: 200px; 
  object-fit: cover; 
}

.portfolio-item-description {
  flex: 1; 
  margin-top: 10px;
  font-size: 0.8rem;
}

.portfolio-item-category {
  font-size: 0.8rem;
  color: #999; 
  margin-bottom: 5px;
}


