.about-skills {
  margin: 40px 0; /* Add some margin for spacing */
  font-family: Arial, sans-serif; /* Set a clean and readable font */
  text-align: center; /* Center the content of the about-skills section horizontally */
}

.about-skills h2 {
  text-align: center; /* Center the heading text (already set) */
  margin-bottom: 20px; /* Add some space between heading and list */
  font-size: 1.8em; /* Increase heading size for emphasis */
  color: #333; /* Set a dark and readable text color */
}

/* New style for skill items */
.skill-item {
  margin: 15px 0; /* Add some margin between skill items */
  padding: 20px; /* Add padding for readability */
  border-radius: 5px; /* Add rounded corners for a softer look */
  background-color: #f5f5f5; /* Add a light background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  transition: all 0.2s ease-in-out; /* Add a smooth hover effect */
  display: inline-block; /* Make skill items behave inline for horizontal centering */
  margin: 0 20px; /* Add horizontal margins for spacing between items */
}

.skill-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
  transform: translateY(-2px); /* Add a slight upward movement on hover */
  background-color: #e0e0e0; /* Change background color slightly on hover */
}

.skill-item h3 {
  margin: 0 0 10px 0; /* Add some bottom margin */
  font-size: 1.2em; /* Increase heading size for hierarchy */
  font-weight: bold; /* Make the skill title bolder */
  color: #333; /* Set a dark and readable text color */
}

.skill-item p {
  font-size: 1em; /* Adjust paragraph font size for readability */
  line-height: 1.5; /* Increase line-height for better spacing */
}

/* Optional: Add icon styling */
.skill-item h3:before {
  /* Uncomment and adjust if you want to include icons */
  content: "\f1e8"; /* Font Awesome icon for example (adjust as needed) */
  font-family: FontAwesome; /* Adjust font family for your icons */
  font-size: 1.5em; /* Adjust icon size */
  color: #333; /* Set a dark and readable text color for icon */
  position: absolute;
  left: 0; /* Align icon to the left of the title */
  top: 5px; /* Adjust icon position vertically */
  transition: all 0.2s ease-in-out; /* Add hover effect for icon */
}

.skill-item:hover h3:before {
  /* Uncomment and adjust if you want to include icons */
  color: #666; /* Change icon color slightly on hover */
}
