/* Overall Project Section */
.projects {
  margin: 4rem auto;
  max-width: 900px;
  background-color: #f0f0f0; /* Light gray background with subtle texture */
  padding: 2rem; /* Increase top/bottom padding for more space */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Subtler drop shadow */
  text-align: center;
}
/*.projects-helper{
  margin: 2rem;
}*/

/* Project Heading */
.projects-heading {
  color: #222; /* Darker text color */
  text-align: center;
  margin: 1rem 0; /* Increase top/bottom margin for more space */
  font-family: 'Playfair Display', serif; /* Elegant heading font */
  font-size: 1.8rem; /* Adjust heading size for better hierarchy */
  font-weight: bold;
  letter-spacing: 1px; /* Add slight letter spacing */
}

/* Project Filters (Consider removing for a cleaner look) */
.project-filters {
  display: flex;
  justify-content: space-between; /* Improved spacing between filters */
  align-items: center;
  margin-bottom: 1rem; /* Maintain some space below filters */
}

.project-filters label {
  margin-right: 1rem; /* Increase space between label and dropdown */
  color: #666; /* Lighter text color for labels */
  font-family: 'Open Sans', sans-serif; /* Clean sans-serif font for labels */
  font-size: 0.8rem; /* Slightly smaller font for labels */
  text-transform: uppercase; /* Convert labels to uppercase for emphasis */
}

/* Dropdown Menus (using .dropdown class) */
.dropdown {
  display: inline-block;
  margin-right: 1rem; /* Increase space between dropdowns */
  border: none; /* Remove default border for a sleeker look */
  background: #eee; /* Light background for dropdowns */
  padding: 0.5rem 1rem; /* Reduce padding for dropdowns */
  border-radius: 10px; /* Rounded corners with a larger radius */
  cursor: pointer;
  font-family: 'Open Sans', sans-serif; /* Consistent font for dropdowns */
  font-size: 0.8rem; /* Slightly smaller font for dropdowns */
  transition: background-color 0.2s ease-in-out; /* Smooth background transition */
}

.dropdown:hover {
  background-color: #ddd; /* Slightly darker background on hover */
}

.filter-dropdown,
.sort-dropdown {
  @extend .dropdown;
}

/* Select element within dropdown */
.dropdown select {
  border: none; /* Remove default border */
  outline: none; /* Remove default outline */
  background: transparent; /* Transparent background */
  width: 100%; /* Fill dropdown width */
}

/* Project Cards */
.project {
  background-color: #fff; /* White background for cards */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtler box shadow for depth */
  border-radius: 3px; /* Reduce rounded corners for a more modern look */
  margin-bottom: 1.5rem; /* Increase space between cards */
  padding: 1.5rem; /* Increase padding for more space within card */
  display: flex;
  flex-direction: column; /* Stack content vertically */
  transition: transform 0.2s ease-in-out; /* Smooth hover animation */
  position: relative; /* Required for hover effects on child elements */
}

.project:hover {
  transform: translateY(-2px); /* Slightly less prominent upward movement on hover */
}

/* Project Image (assuming an "image" class) */


/* Project Description */
.project-description {
  font-family: 'Open Sans', sans-serif; /* Consistent font for descriptions */
  font-size: 1rem; /* Increase font size for improved readability */
  line-height: 1.5; /* Increase line height for more space between lines */
  color: #666; /* Lighter text color for descriptions */
 margin-bottom: 1rem; /* Maintain some space below description */
}

/* Project Description (continued) */
.project-description {
  margin-bottom: 1rem; /* Maintain some space below description */
}

/* Project Technologies/Accomplishments (assuming a "technologies" class) */
.technologies {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
  margin-bottom: 0.5rem; /* Reduce space below technologies */
  margin-top: 0.5rem; /* Add some space above technologies */
}

.technologies span {
  background-color: #eee; /* Lighter background color for tags */
  padding: 0.5rem 1rem; /* Increase padding for better readability */
  border-radius: 3px; /* Reduce rounded corners for a more modern look */
  margin-right: 0.5rem; /* Reduce space between tags */
  font-size: 0.8rem; /* Consistent font size for tags */
  color: #333; /* Darker text color for tags */
  font-weight: bold; /* Bold text for emphasis */
  transition: background-color 0.2s ease-in-out; /* Smooth background transition */
}

.technologies span:hover {
  background-color: #ddd; /* Slightly darker background on hover */
}

/* Project Links (assuming an "links" class) */
.links {
  display: flex;
  justify-content: space-between; /* Align links horizontally */
  align-items: center;
  margin-top: 1rem; /* Increase space above links */
}

.links a {
  color: #666; /* Lighter text color for links */
  text-decoration: none; /* Remove underline */
  transition: color 0.2s ease-in-out; /* Smooth color transition on hover */
  font-weight: bold; /* Bold text for emphasis */
}

.links a:hover {
  color: #222; /* Darker text color on hover */
}

/* Media Queries (optional) - Example for smaller screens */

@media only screen and (max-width: 768px) {
  .projects {
    max-width: 100%; /* Adjust max-width for smaller screens */
  }

  .project-filters {
    flex-direction: column; /* Stack filters vertically on small screens */
  }

  .project-filters label,
  .project-filters select {
    margin-bottom: 0.5rem; /* Adjust spacing for stacked filters */
  }

  .project {
    padding: 1rem; /* Reduce padding for smaller screens */
  }

  /* Additional option for smaller screens: Combine project title and description */
  .project .project-title,
  .project .project-description {
    margin-bottom: 0.5rem; /* Adjust spacing for combined elements */
  }
}

/* Additional Enhancements (Optional) */

.project:hover .project-title,
.project:hover .project-description {
  /* Add subtle hover effect for title and description (e.g., slightly bolder text) */
  font-weight: bold;
}

.project-link {
  text-decoration: none; /* Remove underline */
  color: #007bff; /* Set link color */
  font-weight: bold; /* Make link text bold */
  margin-top: auto; /* Align link to bottom of content */
  transition: color 0.2s ease-in-out; /* Smooth color transition on hover */
}

.project-link:hover {
  color: #0062cc; /* Slightly darker blue on hover */
}

/* Optional Styling Options */

/* Option 1: Add a subtle border to project cards */
.project {
  border: 1px solid #ddd; /* Add a thin border */
}

/* Option 2: Change the hover effect for project cards to a slight opacity change */
.project:hover {
  opacity: 0.9; /* Reduce opacity slightly on hover */
}

/* Option 3: Add a button-like style to project links */
.project-link {
  background-color: #eee; /* Light background for links */
  padding: 0.5rem 1rem; /* Add some padding */
  border: none; /* Remove default button border */
  border-radius: 3px;
    cursor: pointer; /* Change cursor to indicate interactivity */
  transition: background-color 0.2s ease-in-out; /* Smooth background transition */
}

.project-link:hover {
  background-color: #ddd; /* Slightly darker background on hover */
}

/* Option 4: Add a hover effect with text underline for project titles */
.project:hover .project-title {
  text-decoration: underline; /* Add underline on hover */
}

/* Mobile Responsiveness (additional considerations) */

@media only screen and (max-width: 480px) {
  /* Adjust styles for even smaller screens (e.g., mobile phones) */
  .project-filters {
    flex-wrap: wrap; /* Wrap filters on very small screens */
  }

  .technologies {
    flex-direction: column; /* Stack technologies vertically on very small screens */
  }

  .links {
    flex-direction: column; /* Stack links vertically on very small screens */
  }

  /* Adjust font sizes for better readability on mobile */
  .projects-heading,
  .project-title,
  .project-description {
    font-size: 1.2rem;
  }
}
