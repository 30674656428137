/* Base Styles (from your original footer.css) */
@base-font-family: Arial, sans-serif;
@base-background-color: #f5f5f5;

/* Home Page Styles (from your original footer.css) */
/*.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: @base-background-color;
  font-family: @base-font-family;
  margin: 0;
  padding: 0 20px;
}*/

/*.home-header,
.home-main,
.home-footer {
  flex: 1;
}*/

/*.home-header {
  padding: 20px;
  text-align: center;
}*/




.home-main {
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: space-between;
}

h1 {
  margin: 0;
  font-size: 2em;
}

.about-me,
.projects {
  flex: 1;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  margin: 0;
  font-size: 1.5em;
}

p {
  font-size: 1em;
  line-height: 1.5;
}

.home-footer {
  padding: 20px;
  text-align: center;
  background-color: #e0e0e0;
}

.project {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.project-image {
  width: 150px;
  height: auto;
  margin-right: 20px;
  border-radius: 5px;
}

.navigation {
  /* Existing styles */
  align-items: center;
}

/* Responsive Styles (from your original footer.css) */
@media (max-width: 768px) {
  .home-container {
    padding: 1rem;
  }

  .home-main {
    gap: 20px;
  }

  .about-me,
  .projects {
    flex: 1 100%;
  }
}

/* Footer Styles (New styles) */
.footer {
  background-color: #212121;
  color: #fff;
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer-content {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.copyright-container {
  flex: 1;
}

.social-links-container {
  display: flex;
}

.copyright {
  font-size: 0.8rem;
  margin: 0;
}

.social-toggle {
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  font-size: 1.2rem;
  margin-left: 1rem;
  transition: all 0.3s ease;
}

.social-toggle.open {
  transform: rotate(180deg);
}

.social-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
}

.social-links.visible {
  display: flex;
}

.social-links li {
  margin-right: 1rem;
}

.social-links a {
  color: inherit;
  transition: all 0.3s ease;
}

.social-links a:hover {
  color: #33bfff;
}

